/**
 * User Settings Page
 * 
 * Asks a user for their name and email(optional) for use later on.
 * Helps to identify team members within a team, plus if an email is given,
 * a recap email will be sent to them after the game is ended.
 * 
 * Once complete, goes to the Captain Page.
 */

import React, { useContext, useEffect, useState } from 'react'
import { getCurrentUser, setUser, updateUser } from '../utils/auth'
import { isTeamCaptain, getCurrentTeamData, getHuntStatus } from '../utils/team-data'
import { navigate } from 'gatsby'
import { getSiteData } from '../utils/site'
import FloatingInput from '../components/floating-input'
import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

const SettingsPage = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [site_data, setSiteData] = useState({
    about_you_heading: `Tell Us About You`,
    about_you_text: ``,
    about_you_email_text: ``,
    about_you_heading_no_photo: `Tell Us About You`,
    about_you_text_no_photo: ``,
    about_you_email_text_no_photo: ``,
  })
  const [userData, setUserData] = useState({
    guid: ``,
    name: ``,
    email: ``,
    teamName: ``,
    teamCaptain: false,
  })
  const [hunt_status, setHuntStatus] = useState(``)

  useEffect(() => {
    context.setLoading(true)
    const current_user = getCurrentUser()
    setUserData({ ...current_user, teamCaptain: isTeamCaptain() })
    setData(getCurrentHuntData())
    setHuntStatus(getHuntStatus()),
    getSiteData().then(data => {
      setSiteData(data)
      context.setLoading(false)
    })
  }, [])


  const validateFields = () => {
    const { email, name } = userData
    let is_valid = true
    let errors = {}
    setErrors({})
    if(!name.length){
      is_valid = false,
      errors[`name`] = `Please enter your name`
    }
    if(email){
      let lastAtPos = email.lastIndexOf(`@`)
      let lastDotPos = email.lastIndexOf(`.`)

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf(`@@`) == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        is_valid = false
        errors[`email`] = `Please enter a valid email address`
      }
    }
    setErrors(errors)
    return is_valid
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let user_obj = {
      guid: userData.guid,
      name: userData.name,
      email: userData.email,
    }
    
    var is_valid = validateFields()
    if(is_valid){
      context.setLoading(true)
      if (hunt_status == `pending`) {
        updateUser(user_obj).then(() => {
          context.toggleRefresh()
          navigate(`/captain/`)
        })
      } else {
        updateUser(user_obj).then(() => {
          context.toggleRefresh()
          navigate(`/rules/`)
        })
      }
    }
  }

  let client = ``
  if(data.hunt !== undefined){
    if (data.hunt.client_logo !== null) {
      client = (
        <>
          <h4 className="text-center title h5 text-uppercase mb-4">{data.hunt.greeting_text ? data.hunt.greeting_text : `Hi there,`}</h4>
          <div
            style={{
              maxWidth: `400px`,
              margin: `auto`,
              textAlign: `center`,
            }}>
            <img
              style={{
                width: `auto`,
                maxHeight: `75px`,
              }}
              src={data.hunt.client_logo}
            />
          </div>
        </>
      )
    } else {
      client = (
        <h4 className="text-center title h5 text-uppercase mb-4">
          {data.hunt.greeting_text ? data.hunt.greeting_text : `Hi there,`}
          <br /> {data.hunt.client_display}
        </h4>
      )
    }
  }

  let has_photos = data.hunt !== undefined && data.hunt.enable_photos && data.hunt.enable_gallery
  let enable_email_field = data.hunt !== undefined ? data.hunt.enable_email_field : true
  let about_you_heading = has_photos ? site_data.about_you_heading : site_data.about_you_heading_no_photo
  let about_you_text = has_photos ? site_data.about_you_text : site_data.about_you_text_no_photo
  let about_you_email_text = has_photos ? site_data.about_you_email_text : site_data.about_you_email_text_no_photo

  return (
    <LoggedInWrapper>
      <h1 className="sr-only">Settings</h1>
      <div className={`card mt-0 px-4 mx-4 pt-5 mb-3`}>
        <div className={`card-body`}>
          {client && <div className="mb-5">{client}</div>}
          <form
            className={`form`}
            method="post"
            onSubmit={handleSubmit}>
            <h4 className="text-center title h5 text-uppercase mb-4">{about_you_heading}</h4>
            <p>{about_you_text}</p>

            <FloatingInput
              name={`name`}
              placeholder={`Your Name`}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              value={userData.name}
              error={errors[`name`]}
            />
            {enable_email_field && <> 
              {about_you_email_text && <p>{about_you_email_text}</p>}
              <FloatingInput
                name={`email`}
                type={`email`}
                placeholder={`Your Email`}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                value={userData.email}
                error={errors[`email`]}
              />
            </>}

            <button
              className={`btn btn-block btn-danger btn-arrow`}
              onClick={handleSubmit}>
              Next
            </button>
          </form>
        </div>
      </div>
    </LoggedInWrapper>
  )
  
}

export default SettingsPage